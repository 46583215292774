<template>
  <el-dialog
    class="edit"
    :title="title"
    :visible="companyInvestDialog"
    center
    @close="dialogClose"
    width="900px"
    :close-on-click-modal="false"
    :destroy-on-close="false"
  >
    <el-main>
      <el-form>
        <el-row type="flex" justify="end">
          <el-col :span="2">
            <el-form-item>
              <el-button
                type="primary"
                @click="openDialog('add')"
                v-permission="['admin:project:add:category_1']"
                >新增</el-button
              >
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!-- 表格 -->
      <el-table border style="width: 100%" :data="tableData">
        <el-table-column
          label="项目名称"
          prop="projectName"
          width="180"
        ></el-table-column>
        <el-table-column label="投资时间" prop="createDate" width="160">
          <template slot-scope="scope">
            <span>{{ scope.row.investTime | formatDateFilter }}</span>
          </template>
        </el-table-column>
        <el-table-column label="投资金额" prop="investValuation" width="140">
          <template slot-scope="scope">
            <span
              >{{ scope.row.currencyType | currencyType }}
              {{ scope.row.investAmount }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="投资估值" prop="investValuation" width="140">
          <template slot-scope="scope">
            <span
              >{{ scope.row.currencyType | currencyType }}
              {{ scope.row.investValuation }}
            </span>
          </template>
        </el-table-column>

        <el-table-column label="预计收益" prop="estimateIncome" width="140">
          <template slot-scope="scope">
            <span
              >{{ scope.row.currencyType | currencyType }}
              {{ scope.row.estimateIncome }}
            </span>
          </template>
        </el-table-column>

        <el-table-column
          label="投资最新估值"
          prop="latestInvestValuation"
          width="140"
        >
          <template slot-scope="scope">
            <span
              >{{ scope.row.currencyType | currencyType }}
              {{ scope.row.latestInvestValuation }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="最新估值更新日期" prop="createDate" width="160">
          <template slot-scope="scope">
            <span>{{ scope.row.latestValuationTime | formatDateFilter }}</span>
          </template>
        </el-table-column>

        <el-table-column label="项目退出收益" prop="exitIncome" width="140">
          <template slot-scope="scope">
            <span
              >{{ scope.row.currencyType | currencyType }}
              {{ scope.row.exitIncome }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          label="备注"
          prop="remark"
          width="100"
        ></el-table-column>
        <el-table-column label="操作" fixed="right" width="260">
          <template slot-scope="scope">
            <!-- <el-button
              v-permission="['admin:project:find_detail:category_1']"
              type="primary"
              size="mini"
              @click="openDialog('detail', scope.row.id)"
              >详情</el-button
            > -->
            <el-button
              v-permission="['admin:project:edit:category_1']"
              type="primary"
              size="mini"
              @click="openDialog('edit', scope.row.id)"
              >编辑</el-button
            >
            <el-button
              v-permission="['admin:project:edit:category_1']"
              type="primary"
              size="mini"
              @click="openDialog('valuationHistory', scope.row.id)"
              >估值更新历史</el-button
            >

            <el-button
              v-permission="['admin:project:delete:category_1']"
              type="primary"
              size="mini"
              @click="deleteCompanyInvest(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <div class="page-info-class">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="this.pageInfo.pageNum"
          :page-sizes="[10, 20]"
          :page-size="this.pageInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="this.pageInfo.total"
        ></el-pagination>

        <editCompanyInvest
          v-if="editCompanyInvestDialog"
          :type="editCompanyInvestType"
          :editCompanyInvestDialog="editCompanyInvestDialog"
          v-on:listenEditCompanyInvestClose="listenEditCompanyInvestClose"
          :id="detailId"
        ></editCompanyInvest>

        <valuationHistory
          v-if="valuationHistoryDialog"
          :valuationHistoryDialog="valuationHistoryDialog"
          v-on:listenValuationHistoryClose="listenValuationHistoryClose"
          :id="detailId"
        ></valuationHistory>
      </div>
    </el-main>
  </el-dialog>
</template>

<script>
import { getCompanyInvestList, deleteCompanyInvest } from "@/api/fundCompany";
import { formatDate } from "@/common/date";
import editCompanyInvest from "@/components/fundCompany/editCompanyInvest";
import valuationHistory from "@/components/fundCompany/valuationHistory";
export default {
  name: "companyInvest",
  props: {
    companyInvestDialog: Boolean,
    id: String,
    investName:String
    
  },
  data() {
    return {
      title: this.investName + " - 投资项目",
      tableData: [],
      pageInfo: {
        total: 0,
        pageNum: 1,
        pageSize: 10,
        pageCount: 1,
      },
      queryParams: {
        pageSize: 10,
        pageNum: 1,
        companyId: this.id,
      },
      editCompanyInvestType: null,
      companyDetailDialog: false,
      detailId: null,
      editCompanyInvestDialog: false, //
      valuationHistoryDialog: false,
    };
  },
  components: {
    editCompanyInvest,
    valuationHistory,
  },
  filters: {
    formatDateFilter(time) {
      var date = new Date(time);
      return formatDate(date, "yyyy-MM-dd hh:mm:ss");
    },
    currencyType(value) {
      switch (value) {
        case "CNY":
          return "¥";
          break;
        case "USD":
          return "$";
          break;
      }
    },
  },
  created() {},
  mounted() {
    this.getCompanyInvestList(1, this.pageInfo.pageSize);
  },
  //keep-alive的生命周期 ——进入组件
  activated() {
    console.log("activated2====");
  },
  //keep-alive的生命周期 ——离开组件
  deactivated() {
    console.log("deactivated2====");
    this.getCompanyInvestList(1, this.pageInfo.pageSize);
  },
  methods: {
    //弹窗关闭
    dialogClose() {
      this.params = {};
      this.$emit("listenCompanyInvestClose", { type: "cancel", isClose: true });
    },
    listenValuationHistoryClose(params) {
      const { type, isClose } = params;
      this.valuationHistoryDialog = !isClose;
    },
    listenCompanyDetailClose(params) {
      const { type, isClose } = params;
      this.companyDetailDialog = !isClose;
    },
    listenEditCompanyInvestClose(params) {
      const { type, isClose } = params;
      this.editCompanyInvestDialog = !isClose;
      if (type == "sure") {
        this.getCompanyInvestList(
          this.pageInfo.pageNum,
          this.pageInfo.pageSize
        );
      }
    },

    querySearch(queryString, cb) {
      //项目模糊查找
      var arrCompany = [];
      this.tableData.forEach((res) => {
        arrCompany.push({
          value: res.projectName,
          id: res.id,
        });
      });
      var results = queryString
        ? arrCompany.filter(this.createFilter(queryString))
        : arrCompany;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },

    getCompanyInvestList(num, pageSize) {
      //项目库列表
      this.queryParams.pageNum = num;
      this.queryParams.pageSize = pageSize;
      getCompanyInvestList(this.queryParams).then((response) => {
        this.tableData = response.data;
        // console.log(this.tableData);
        if (response.total != null) {
          this.pageInfo.pageNum = response.pageNum;
          this.pageInfo.pageSize = response.pageSize;
          this.pageInfo.total = response.total;
          this.pageInfo.pageCount = response.pageCount;
        } else {
          this.pageInfo.total = 0;
        }
      });
    },

    handleSizeChange(val) {
      console.log(val);
      this.getCompanyInvestList(1, val);
    },
    handleCurrentChange(val) {
      console.log(val);
      if (val > this.pageInfo.pageCount) {
        val = this.pageInfo.pageCount;
      }
      this.getCompanyInvestList(val, this.pageInfo.pageSize);
    },
    openDialog(type, val) {
      if (val) {
        this.detailId = String(val);
      }
      if (type == "add") {
        this.editCompanyInvestType = "add";
        this.editCompanyInvestDialog = true;
      } else if (type == "edit") {
        this.editCompanyInvestType = "edit";
        this.editCompanyInvestDialog = true;
      } else if (type == "valuationHistory") {
        this.valuationHistoryDialog = true;
      }
    },

    deleteCompanyInvest(data) {
      this.$confirm("确认删除 [" + data.projectName + "] 吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteCompanyInvest(data.id).then((response) => {
            if (response.success) {
              this.$message({ type: "success", message: "删除成功!" });
              this.dialogStatus = false;
              this.getCompanyInvestList(
                this.pageInfo.pageNum,
                this.pageInfo.pageSize
              );
            } else {
              this.$message({
                type: "info",
                message: response.message,
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
  },

  watch: {},
};
</script>

<style lang="scss" scoped>
.projectFrom {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.projectFrom .el-form-item {
  width: 20rem;
  flex-shrink: 0;
}
.editDialogs >>> .el-dialog__body {
  text-align: left;
}
.editDialogs ul {
  list-style: none;
  text-align: left;
  line-height: 30px;
}
.dialog-footer {
  /* margin-top: 100px; */
  text-align: center !important;
  .el-button {
    margin: 0 50px;
  }
}
.el-dialog__footer {
  /* margin-top: 100px; */
  text-align: center !important;
}
</style>
