import { render, staticRenderFns } from "./editCompanyInvest.vue?vue&type=template&id=24551ece&scoped=true&"
import script from "./editCompanyInvest.vue?vue&type=script&lang=js&"
export * from "./editCompanyInvest.vue?vue&type=script&lang=js&"
import style0 from "./editCompanyInvest.vue?vue&type=style&index=0&id=24551ece&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24551ece",
  null
  
)

export default component.exports