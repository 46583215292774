<template>
  <el-dialog
    class="edit"
    :title="title"
    :visible="editCompanyInvestDialog"
    center
    @close="dialogClose"
    width="900px"
    :close-on-click-modal="false"
    :destroy-on-close="false"
    append-to-body
  >
    <el-form
      :model="params"
      ref="params"
      :rules="rules"
      label-width="130px"
      class="addDailog"
    >
      <el-form-item label="项目" prop="projectId">
        <el-select
          v-model="params.projectId"
          class="childWidth"
          filterable
          placeholder="请选择项目"
          @visible-change="searchPorjectList($event)"
          @change="choosePorject($event)"
          clearable
          no-data-text="加载中···"
        >
          <el-option
            v-for="item in porjectListOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="投资时间" prop="investTime">
        <el-date-picker
          v-model="params.investTime"
          type="datetime"
          placeholder="选择日期时间"
          align="right"
          :picker-options="pickerOptions"
              value-format="timestamp"

        >
        </el-date-picker>
      </el-form-item>

      <el-form-item label="投资金额" style="" prop="investAmount">
        <el-input-number
          style="width: calc(100% - 100px) !important"
          type="number"
          show-word-limit
          v-model.number="params.investAmount"
          width="60"
          :min="0"
          :controls="false"
          :precision="2"
        ></el-input-number>
        <el-select
          style="width: 100px !important"
          :reserve-keyword="false"
          v-model="params.currencyType"
          placeholder="请选择"
          clearable
          no-data-text="加载中···"
        >
          <el-option
            v-for="item in currencyTypeList"
            :key="item.code"
            :label="item.name"
            :value="item.code"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="投资估值" prop="investValuation">
        <el-input-number
          style="width: calc(100% - 100px) !important"
          type="number"
          show-word-limit
          v-model.number="params.investValuation"
          width="60"
          :min="0"
          :controls="false"
          :precision="2"
        ></el-input-number>
        <el-select
          style="width: 100px !important"
          :reserve-keyword="false"
          v-model="params.currencyType"
          placeholder="请选择"
          clearable
          no-data-text="加载中···"
        >
          <el-option
            v-for="item in currencyTypeList"
            :key="item.code"
            :label="item.name"
            :value="item.code"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="最新投资估值" prop="latestInvestValuation">
        <el-input-number
          style="width: calc(100% - 100px) !important"
          type="number"
          show-word-limit
          v-model.number="params.latestInvestValuation"
          width="60"
          :min="0"
          :controls="false"
          :precision="2"
        ></el-input-number>
        <el-select
          style="width: 100px !important"
          :reserve-keyword="false"
          v-model="params.currencyType"
          placeholder="请选择"
          clearable
          no-data-text="加载中···"
        >
          <el-option
            v-for="item in currencyTypeList"
            :key="item.code"
            :label="item.name"
            :value="item.code"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- sj -->
      <el-form-item label="最新估值更新日期" prop="latestValuationTime">
        <el-date-picker
          v-model="params.latestValuationTime"
          type="datetime"
          placeholder="选择日期时间"
          align="right"
          :picker-options="pickerOptions"
              value-format="timestamp"

        >
        </el-date-picker>
      </el-form-item>
      <!--  -->
      <el-form-item label="预计收益" prop="estimateIncome">
        <el-input-number
          style="width: calc(100% - 100px) !important"
          type="number"
          show-word-limit
          v-model.number="params.estimateIncome"
          width="60"
          :min="0"
          :controls="false"
          :precision="2"
        ></el-input-number>
        <el-select
          style="width: 100px !important"
          :reserve-keyword="false"
          v-model="params.currencyType"
          placeholder="请选择"
          clearable
          no-data-text="加载中···"
        >
          <el-option
            v-for="item in currencyTypeList"
            :key="item.code"
            :label="item.name"
            :value="item.code"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="退出收益" prop="exitIncome">
        <el-input-number
          style="width: calc(100% - 100px) !important"
          type="number"
          show-word-limit
          v-model.number="params.exitIncome"
          width="60"
          :min="0"
          :controls="false"
          :precision="2"
        ></el-input-number>
        <el-select
          style="width: 100px !important"
          :reserve-keyword="false"
          v-model="params.currencyType"
          placeholder="请选择"
          clearable
          no-data-text="加载中···"
        >
          <el-option
            v-for="item in currencyTypeList"
            :key="item.code"
            :label="item.name"
            :value="item.code"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="el-form-item2" label="备注" prop="remark">
        <el-input
          type="textarea"
          :rows="3"
          autosize
          v-model="params.remark"
          show-word-limit
        ></el-input>
      </el-form-item>
    </el-form>
    <div class="dialog-footer">
      <el-button @click="dialogClose">取消</el-button>
      <el-button type="primary" @click="sure">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {
  addCompanyInvest,
  editCompanyInvest,
  companyInvestDetail,
} from "@/api/fundCompany";
import { CURRENCY_TYPE_LIST, INDUSTRY_LIST } from "@/common/constants";
import { selectListMeetingProject } from "@/api/minutesOfMeeting";
export default {
  name: "editCompanyInvest",
  props: {
    type: String,
    editCompanyInvestDialog: Boolean,
    id: String,
  },
  data() {
    return {
      title: null,
      rules: {
        investAmount: [
          { required: false, message: "请输入投资金额", trigger: "blur" },
        ],
        projectId: [
          { required: false, message: "请选择项目", trigger: "change" },
        ],
      },
      porjectListOptions: [],
      params: {
          companyId:this.id,
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      currencyTypeList: CURRENCY_TYPE_LIST, //货币类型(ISO4217)
      
    };
  },
  computed: {},
  created() {
    if (this.type == "edit") {
      this.title = "编辑";
      this.searchPorjectList(true);
      this.companyInvestDetail(this.id);
    } else if (this.type == "add") {
      this.title = "新增";
    }
  },
  mounted() {},
  methods: {
    choosePorject(id) {
      console.log(id);
    },
    searchPorjectList(cb) {
      if (cb) {
        selectListMeetingProject({category:'1'}).then((response) => {
          if (response.success) {
            this.porjectListOptions = response.data;
          }
        });
      }
    },
    addCompanyInvest() {
      addCompanyInvest(this.params).then((response) => {
        if (response.success) {
          this.$message({ type: "success", message: "添加成功!" });
          this.params = {};
          this.$emit("listenEditCompanyInvestClose", {
            type: "sure",
            isClose: true,
          });
        }
      });
    },
    editCompanyInvest() {
        this.params.companyId = this.id;
      editCompanyInvest(this.params).then((response) => {
        if (response.success) {
          this.$message({ type: "success", message: "编辑成功!" });
          this.params = {};
          this.$emit("listenEditCompanyInvestClose", {
            type: "sure",
            isClose: true,
          });
        }
      });
    },
    //确定
    sure() {

      if (this.type == "add") {
        this.addCompanyInvest();
      } else if (this.type == "edit") {
        this.editCompanyInvest();
      }
    },
    companyInvestDetail(id) {
      //判断是否回显
      companyInvestDetail(id).then((res) => {
        if (res.success) {
          this.params = res.data;
        }
      });
    },
    //弹窗关闭
    dialogClose() {
      this.params = {};
      this.$emit("listenEditCompanyInvestClose", {
        type: "cancel",
        isClose: true,
      });
    },
  },

  beforeCreate() {},
  beforeMount() {},
  watch: {
    msPd(n, o) {
      //监听新增|编辑传过来的对象
      //箭头函数  不然会发生this改变
      this.objProject(n);
    },
    deep: true,
  },
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
  components: {},
};
</script>

<style lang="scss" scoped>
.addDailog {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  .el-form-item2 {
    width: 100% !important;
    height: auto;
    text-align: left !important;
  }
  .el-form-item {
    text-align: left;
    width: 49%;
    .el-select {
      width: 100% !important;
    }
    .el-date-picker {
      width: 100% !important;
    }
    .el-date-editor.el-input {
      width: 100% !important;
    }
    .el-input-number {
      width: 100% !important;
      text-align: left;
      .el-input__inner {
        text-align: left !important;
      }
    }
    .addContributionRatio {
      width: 20% !important;
      float: right;
      text-align: center;
      i {
        margin: 0 2px;
      }
    }
  }
}
</style>